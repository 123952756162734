.Home {
  padding-bottom: 150px;
}

.Home .section.header {
  width: 100vw;
  height: 100vh;
  background-image: url("../../assets/images/456.jpg");
  background-size: cover;
}
.Home .section.header .title {
  padding-top: calc(50vh - 15vw);
  text-align: center;
  font-size: 9vw;
  font-weight: 900;
  line-height: 8vw;
}
.Home .section.header .text {
  text-align: center;
  font-size: 2.5vw;
  font-weight: 600;
  margin-top: 50px;
  color: rgba(255, 255, 255, 0.8);
}
.Home .section.header .ctn-button {
  display: flex;
  justify-content: center;
}
.Home .section.header .button {
  background-color: white;
  color: black;
  margin-top: 30px;
  font-weight: 600;
  padding: 10px 30px;
  border-radius: var(--border-radius-default);
  text-decoration: none;
  outline: none;
  transform: scale(1.3);
}

.Home .section-image {
  display: flex;
  justify-content: space-between;
  margin-top: 100px;
}
.Home .section-image .ctn-content {
  padding-left: 40px;
  max-width: 40vw;
  margin-top: 5vw;
}
.Home .section-image.right .ctn-content {
  padding-right: 40px;
}
.Home .section-image .ctn-content .sub-title {
  color: rgba(255, 255, 255, 0.757);
  text-transform: uppercase;
  font-weight: 500;
}
.Home .section-image .ctn-content .title {
  font-size: 45px;
  font-weight: 600;
}
.Home .section-image .ctn-content .text {
  margin-top: 20px;
  font-size: 24px;
}
.Home .section-image img {
  width: 100%;
  max-width: 550px;
  border-radius: var(--border-radius-default);
  margin: 0 60px;
}

.Home .section.footer {
  margin-top: 50px;
  text-align: center;
}
.Home .section.footer .line {
  width: 50px;
  height: 3px;
  background-color: white;
  border-radius: 15px;
  margin: auto;
  margin-bottom: 50px;
}
.Home .section.footer a {
  color: white;
}

/* media screen < 900px */
@media screen and (max-width: 900px) {
  .Home .section.header .text {
    font-size: 18px;
    padding: 0 40px;
    margin-top: 15px;
  }
  .Home .section-image {
    display: flex;
    flex-direction: column;
    margin-top: 100px;
  }
  .Home .section-image.left {
    flex-direction: column-reverse;
  }
  .Home .section-image .ctn-image {
    margin: auto;
  }
  .Home .section-image img {
    max-width: 70vh;
  }
  .Home .section-image .ctn-content {
    max-width: 100vw;
    text-align: center;
    padding: 0 40px;
    margin-top: 5vw;
  }
  .Home .section.footer {
    margin-top: 50px;
  }
}
