.Photos {
  max-width: 1000px;
  padding: 40px;
  margin: auto;
  padding-top: 80px;
  padding-bottom: 150px;
}
.Photos .header {
  margin-bottom: 80px;
}
.Photos .header .title {
  color: white;
}

.Photos .gallery .gallery-container {
  columns: 4;
  column-gap: 16px;
}
@media screen and (max-width: 900px) {
  .Photos .gallery .gallery-container {
    columns: 3;
  }
}
@media screen and (max-width: 680px) {
  .Photos {
    padding: inherit 20px;
  }
  .Photos .gallery .gallery-container {
    columns: 2;
  }
}
@media screen and (max-width: 350px) {
  .Photos .gallery .gallery-container {
    columns: 1;
  }
}
.gallery-item {
  display: inline-block;
  margin-bottom: 16px;
}

.gallery-item img {
  width: 100%;
  height: auto;
  display: block;
  object-fit: cover; /* Assurez-vous que l'image remplit complètement le conteneur */
  border-radius: var(--border-radius-default);
}
.Photos .gallery .overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
.Photos .gallery .image-modal {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 100px;
  top: 40px;
  margin: auto;
  width: calc(100vw - 80px);
  height: calc(100vh - 120px);
  object-fit: contain;
}
.Photos .gallery .image-modal img {
  position: relative;
  display: block;
  width: auto;
  max-width: 100%;
  max-height: 100%;
  height: auto;
  object-fit: contain;
  margin: auto;
  z-index: 10;
}
.Photos .gallery .bottom-bar {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 20px;
  display: flex;
  align-items: center;
  margin: auto;
}
.Photos .gallery .container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  max-width: 200px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.53);
  backdrop-filter: blur(5px);
  color: black;
  border-radius: 30px;
}
.Photos .gallery .container .icon {
  display: flex;
  align-items: center;
  font-size: 20px;
}
.Photos .gallery .container .index {
  background-color: #eee;
  color: black;
  padding: 12px 15px;
  border-radius: 30px;
  font-weight: 600;
  margin: 0 10px;
}
.Photos .gallery .bg {
  display: flex;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100vw;
  height: 100vh;
  z-index: 9;
}
.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}
.pagination .container {
  display: flex;
  align-items: center;
  margin: auto;
  border-radius: 30px;
  background-color: white;
}
.pagination .container button {
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  font-size: 18px;
  font-weight: 600;
  background-color: white;
  border-radius: 0px;
  padding: 8px 20px;
}
.pagination .container button:nth-child(1) {
  transform: translateX(-1px);
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}
.pagination .container button:last-child {
  transform: translateX(1px);
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}
.pagination .container button .icon {
  font-size: 20px;
}
.pagination .container .active {
  background-color: #111;
  color: white;
}
