.BottomBar {
  position: fixed;
  left: 0;
  bottom: 50px;
  right: 0;
  display: flex;
  font-size: 20px;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.53);
  backdrop-filter: blur(5px);
  border-radius: 30px;
  color: black;
  margin: auto;
  max-width: 300px;
  box-shadow: var(--box-shadow);
  transition: var(--transition);
  z-index: 5;
}
.BottomBar .container {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.BottomBar .container.active {
  background-color: #eee;
  border-radius: 30px;
  padding: 12px 15px;
  color: black;
}
.BottomBar .container .text {
  font-size: 15px;
  font-weight: 600;
  margin-left: 5px;
}
