.Contact {
  max-width: 1000px;
  padding: 40px;
  margin: auto;
  padding-top: 80px;
  padding-bottom: 150px;
}
.Contact .header {
  margin-bottom: 80px;
}
.Contact .header .title {
  color: white;
}

.Contact .wrapper .ctn-intro {
  text-align: center;
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 30px;
}

.Contact .wrapper .ctn-elfb {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}
.Contact .wrapper .ctn-elfb .flex {
  display: flex;
  align-items: center;
  margin: auto;
  max-width: 500px;
  width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.154);
  padding: 15px 25px;
  color: white;
  border-radius: 50px;
}
.Contact .wrapper .ctn-elfb .ctn {
  display: flex;
  align-items: center;
  margin: auto;
  width: calc(50% - 12.5px);
}
.Contact .wrapper .ctn-elfb .ctn:first-child {
  border-right: 1px solid rgba(255, 255, 255, 0.154);
  margin-right: 20px;
  padding-right: 5px;
}
@media screen and (max-width: 600px) {
  .Contact .wrapper .ctn-elfb .flex {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
  .Contact .wrapper .ctn-elfb .ctn {
    margin: 0;
    width: auto;
  }
  .Contact .wrapper .ctn-elfb .ctn:first-child {
    border-right: none;
    margin-right: 0;
    padding-right: 0;
  }
}
.Contact .wrapper .ctn-elfb .ctn-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background-color: rgb(100, 226, 100);
  margin-right: 10px;
}
.Contact .wrapper .ctn-elfb .ctn-icon .icon {
  font-size: 22px;
  color: rgb(22, 99, 22);
}
.Contact .wrapper .ctn-elfb .ctn-icon.fb {
  background-color: rgb(54, 137, 255);
}
.Contact .wrapper .ctn-elfb .ctn-icon.fb .icon {
  font-size: 20px;
  color: rgb(0, 23, 138);
}
.Contact .wrapper .ctn-elfb .left span {
  color: rgba(255, 255, 255, 0.747);
  font-size: 13px;
  font-weight: 600;
}
.Contact .wrapper .ctn-elfb .left .text {
  font-weight: 500;
}

.Contact .wrapper .ctn-open {
  display: block;
  margin-top: 70px;
}
.Contact .wrapper .ctn-open a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  background-color: white;
  text-decoration: none;
  padding: 10px 15px;
  border-radius: 30px;
  box-shadow: rgba(255, 255, 255, 0.2) 0px 8px 24px;
  outline: none;
  margin: auto;
  transition: all 0.2s;
}
.Contact .wrapper .ctn-open a:hover {
  background-color: rgb(245, 245, 245);
  transform: scale(0.95);
}
.Contact .wrapper .ctn-open a img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
